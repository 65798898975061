import React from 'react';
import { Link } from 'react-router-dom';
import './ThankYou.css';

export default function DevOpsThankYou() {
  return (
    <div className="thank-you-container">
      <div className="thank-you-content">
        <h1>Thank You for Applying!</h1>
        
        <div className="message-section">
          <p className="main-message">
            I've received your application for the DevOps Mentorship Program.
          </p>
          
          <div className="next-steps">
            <h2>What's Next?</h2>
            <ul>
              <li>
                <span className="step-number">1</span>
                I'll review your application within 24 hours
              </li>
              <li>
                <span className="step-number">2</span>
                You'll receive an email with scheduling details for our initial consultation
              </li>
              <li>
                <span className="step-number">3</span>
                We'll discuss your goals and create a personalized learning path
              </li>
            </ul>
          </div>

          <div className="preparation">
            <h2>While You Wait</h2>
            <p>
              Feel free to check out my blog posts about DevOps best practices and cloud architecture:
            </p>
            <div className="blog-links">
              <Link to="/blog/devops-consulting-what-businesses-need-to-know" className="blog-link">
                DevOps Consulting Guide
              </Link>
              <Link to="/blog/what-is-infrastructure-as-code" className="blog-link">
                Infrastructure as Code Explained
              </Link>
              <Link to="/blog/deploy-website-for-free-on-aws" className="blog-link">
                AWS Deployment Guide
              </Link>
            </div>
          </div>
        </div>

        <div className="contact-info">
          <p>
            If you have any questions, feel free to reach out to me directly at{' '}
            <a href="mailto:bane@brankopetric.com" className="email-link">
              bane@brankopetric.com
            </a>
          </p>
        </div>

        <Link to="/" className="btn btn-primary home-button">
          Back to Home
        </Link>
      </div>
    </div>
  );
} 