import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DevOps.css";

export default function DevOpsPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    experience: "",
    message: ""
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      body: JSON.stringify({
        firstName: formData.name.split(' ')[0],
        lastName: formData.name.split(' ').slice(1).join(' ') || 'Not Provided',
        email: formData.email,
        topic: 'DevOps Mentorship',
        message: `Experience Level: ${formData.experience}\nGoals: ${formData.message}`,
      }),
    };

    try {
      const response = await fetch(
        'https://tgllk21vo1.execute-api.us-east-1.amazonaws.com/prod/form',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        navigate('/devops/thank-you');
      } else {
        console.error('Failed to submit the form');
        alert('Failed to submit the form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const [activeModule, setActiveModule] = useState(1);

  const modules = [
    {
      id: 1,
      title: "Infrastructure & Cloud",
      icon: "🏗️",
      description: "Master AWS cloud infrastructure and modern architecture practices",
      topics: [
        "AWS Cloud fundamentals and best practices",
        "AWS IAM and security fundamentals",
        "AWS Networking and VPC design",
        "Infrastructure as Code with Terraform",
        "Disaster Recovery strategies",
        "Cost Optimization techniques",
        "Serverless architecture and Lambda"
      ]
    },
    {
      id: 2,
      title: "CI/CD & Automation",
      icon: "🔄",
      description: "Learn modern deployment pipelines and automation strategies",
      topics: [
        "Git Workflow strategies",
        "GitHub Actions and pipeline automation",
        "Secrets Management best practices",
        "Advanced deployment strategies"
      ]
    },
    {
      id: 3,
      title: "Containers & Orchestration",
      icon: "🐳",
      description: "Deep dive into containerization and Kubernetes",
      topics: [
        "Docker containerization fundamentals",
        "Kubernetes cluster management",
        "Helm Charts for package management",
        "Secrets in container environments",
        "Container Security best practices"
      ]
    },
    {
      id: 4,
      title: "Monitoring & Logging",
      icon: "📊",
      description: "Implement robust monitoring and logging solutions",
      topics: [
        "Monitoring & logging fundamentals",
        "AWS CloudWatch implementation",
        "Alert management and notifications"
      ]
    },
    {
      id: 5,
      title: "GitOps",
      icon: "🔱",
      description: "Master GitOps principles and tools",
      topics: [
        "GitOps principles and practices",
        "ArgoCD implementation and management"
      ]
    },
    {
      id: 6,
      title: "AI in DevOps",
      icon: "🤖",
      description: "Integrate AI capabilities into your DevOps workflow",
      topics: [
        "AI & ML fundamentals",
        "AWS SageMaker implementation",
        "AWS Bedrock integration",
        "AI model deployment strategies",
        "Model evaluation and monitoring",
        "AI-powered DevOps automation"
      ]
    }
  ];

  return (
    <div className="devops-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>DevOps Mentorship Program</h1>
          <p className="subtitle">3-week intensive program to accelerate your DevOps career</p>
          <ul className="hero-features">
            <li>Personalized learning path</li>
            <li>One-on-one sessions</li>
            <li>Hands-on projects</li>
            <li>Code reviews</li>
          </ul>
        </div>
        <div className="hero-image">
          <img src="/img/hero_img3.webp" alt="DevOps Mentorship" />
        </div>
      </section>

      {/* Curriculum Section */}
      <section className="curriculum-section">
        <h2>What You'll Learn</h2>
        <p className="section-description">Comprehensive curriculum covering modern DevOps practices</p>
        
        <div className="modules-container">
          <div className="modules-nav">
            {modules.map((module) => (
              <div
                key={module.id}
                className={`module-nav-item ${activeModule === module.id ? 'active' : ''}`}
                onClick={() => setActiveModule(module.id)}
              >
                <span className="module-icon">{module.icon}</span>
                <h3>{module.title}</h3>
              </div>
            ))}
          </div>
          
          <div className="module-content">
            {modules.map((module) => (
              <div
                key={module.id}
                className={`module-details ${activeModule === module.id ? 'active' : ''}`}
              >
                <div className="module-header">
                  <span className="module-icon-large">{module.icon}</span>
                  <div>
                    <h3>{module.title}</h3>
                    <p>{module.description}</p>
                  </div>
                </div>
                <ul className="module-topics">
                  {module.topics.map((topic, index) => (
                    <li key={index}>{topic}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Sign Up Section */}
      <section className="signup-section">
        <h2>Join the Program</h2>
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Enter your full name"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Enter your email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="experience">DevOps Experience Level</label>
            <select
              id="experience"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              required
            >
              <option value="">Select your experience level</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="message">What do you want to achieve?</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              required
              placeholder="Tell me about your goals and what you want to learn"
            ></textarea>
          </div>

          <button type="submit" className="btn btn-primary">Apply Now</button>
        </form>
      </section>
    </div>
  );
} 