import "./App.css";
import { React, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./Pages/Home/Navbar";
import Home from "./Pages/Home/Homescreen";
import Blog from "./Pages/Blog/Page"; // Blog listing page
import BlogPost from "./Pages/Blog/BlogPost"; // Single blog post page
import NotFound from "./Pages/NotFound";
import Cookies from "./Pages/Cookies";
import ThankYou from "./Pages/ThankYou";
import KubeWhisper from "./Pages/KubeWhisper";
import ThankYou2 from "./Pages/ThankYou2";
import Lambda from "./Pages/Lambda";
import LambdaThankYou from "./Pages/LambdaThankYou";
import DevOps from "./Pages/DevOps/DevOps";
import DevOpsThankYou from "./Pages/DevOpsThankYou";
import ReactGA from "react-ga4";

// A new component to handle the Router context and GA tracking
function AppWrapper() {
  const location = useLocation();

  useEffect(() => {
    // Initialize Google Analytics with your Measurement ID
    ReactGA.initialize("G-H6B5HCVD3C"); // Replace with your own Measurement ID
  }, []);

  useEffect(() => {
    // Track page views on route changes
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>
      <Navbar />
      <div className="page-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} /> {/* Dynamic blog post route */}
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/kubewhisper" element={<KubeWhisper />} />
          <Route path="/kubewhisper/thank-you" element={<ThankYou2 />} />
          <Route path="/lambda" element={<Lambda />} />
          <Route path="/lambda/thank-you" element={<LambdaThankYou />} />
          <Route path="/devops" element={<DevOps />} />
          <Route path="/devops/thank-you" element={<DevOpsThankYou />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <AppWrapper />
      </Router>
    </div>
  );
}

export default App;